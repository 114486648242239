<template>
  <div class="container-fluid">
    <main  class="tm-main">
      <div class="row">
        <div class="col-md-3 col-sm-6">
          <div class="product-grid">
            <div class="product-image">
              <a href="#" class="image">
                <img class="pic-1" src="https://bitprice.ru/sites/default/files/styles/mt_photo/public/img/logo/brands/164820_0.png?itok=PPzjnHvf">
                <img class="pic-2" src="https://bitprice.ru/sites/default/files/styles/mt_photo/public/img/logo/brands/164820_0.png?itok=PPzjnHvf">
              </a>
            </div>
            <div class="product-content">
              <h3 class="title"><a href="#">Moulinex</a></h3>
            </div>
          </div>
        </div>


        <div class="col-md-3 col-sm-6">
          <div class="product-grid">
            <div class="product-image">
              <a href="#" class="image">
                <img class="pic-1" src="https://play-lh.googleusercontent.com/0pLSmu4zVcAkniH3mktZ0Y04OTHdQVo_gLUwMeOcrGEOHoBLGiU6nWc9rY__n8ZjADjf">
                <img class="pic-2" src="https://play-lh.googleusercontent.com/0pLSmu4zVcAkniH3mktZ0Y04OTHdQVo_gLUwMeOcrGEOHoBLGiU6nWc9rY__n8ZjADjf">
              </a>
            </div>
            <div class="product-content">
              <h3 class="title"><a href="#">Thermomix</a></h3>
            </div>
          </div>
        </div>


        <div class="col-md-3 col-sm-6">
          <div class="product-grid">
            <div class="product-image">
              <a href="#" class="image">
                <img class="pic-1" src="https://mir-s3-cdn-cf.behance.net/projects/404/800f5c28856103.55d598732b81c.jpg">
                <img class="pic-2" src="https://mir-s3-cdn-cf.behance.net/projects/404/800f5c28856103.55d598732b81c.jpg">
              </a>
            </div>
            <div class="product-content">
              <h3 class="title"><a href="#">Tefal</a></h3>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
export default {
  name: "Helper",

  computed: {
    Language(){
      if (localStorage.getItem('language')==='en')
        return false
      else
        return true
    }
  },
}
</script>

<style scoped>
.product-grid{
  background-color: #fff;
  font-family: 'Work Sans', sans-serif;
  text-align: center;
  transition: all 0.3s ease 0s;
}
.product-grid:hover{ box-shadow:  0 0 20px -10px rgba(237,29,36,0.3); }
.product-grid .product-image{
  overflow: hidden;
  position: relative;
  transition: all 0.3s ease 0s;
}
.product-grid:hover .product-image{ border-radius: 0 0 30px 30px; }
.product-grid .product-image a.image{ display: block; }
.product-grid .product-image img{
  width: 100%;
  height: auto;
}
.product-image .pic-1{
  backface-visibility: hidden;
  transition: all 0.5s ease 0s;
}
.product-grid:hover .product-image .pic-1{ opacity: 0; }
.product-image .pic-2{
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  transition: all 0.5s ease 0s;
}
.product-grid:hover .product-image .pic-2{ opacity: 1; }
.product-grid .product-links{
  padding: 0;
  margin: 0;
  list-style: none;
  opacity: 0;
  position: absolute;
  bottom: 0;
  right: 10px;
  transition: all 0.3s ease 0s;
}
.product-grid:hover .product-links{ opacity: 1; }
.product-grid .product-links li{
  margin: 0 0 10px 0;
  transform: rotate(360deg) scale(0);
  transition: all 0.3s ease 0s;
}
.product-grid:hover .product-links li{ transform: rotate(0) scale(1); }
.product-grid:hover .product-links li:nth-child(3){ transition-delay: 0.1s; }
.product-grid:hover .product-links li:nth-child(2){ transition-delay: 0.2s; }
.product-grid:hover .product-links li:nth-child(1){ transition-delay: 0.3s; }
.product-grid .product-links li a{
  color: #666;
  background-color: #fff;
  font-size: 18px;
  line-height: 42px;
  width: 40px;
  height: 40px;
  border-radius: 50px;
  display: block;
  transition: all 0.3s ease 0s;
}
.product-grid .product-links li a:hover{
  color: #fff;
  background-color: #ed1d24;
}
.product-grid .product-content{
  text-align: left;
  padding: 15px 10px;
}
.product-grid .rating{
  padding: 0;
  margin: 0 0 7px;
  list-style: none;
}
.product-grid .rating li{
  color: #f7bc3d;
  font-size: 13px;
}
.product-grid .rating li.far{ color: #777; }
.product-grid .title{
  font-size: 16px;
  font-weight: 600;
  text-transform: capitalize;
  margin: 0 0 6px;
}
.product-grid .title a{
  color: #555;
  transition: all 0.3s ease 0s;
}
.product-grid .title a:hover{ color: #ed1d24; }
.product-grid .price{
  color: #ed1d24;
  font-size: 18px;
  font-weight: 700;
}
@media screen and (max-width:990px){
  .product-grid{ margin: 0 0 30px; }
}
</style>